import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import Author from '../../author'
import { StyledCard } from './styledComponents'

const ArticleCard = ({ title, link, image, author, ...rest }) => {
  return (
    <StyledCard {...rest}>
      <Link to={link}>
        <div className='overlay' />
        <Img className='image' fluid={image.mediaFile.childImageSharp.fluid} />
        <h2 className='title'>{title}</h2>
        <Author
          className='author'
          {...author}
          avatar={author.avatar.mediaFile.publicURL}
        />
      </Link>
    </StyledCard>
  )
}

ArticleCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  author: PropTypes.shape({
    name: PropTypes.string,
    position: PropTypes.string,
    avatar: PropTypes.object,
  }).isRequired,
}

export default ArticleCard
