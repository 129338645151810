import styled from 'styled-components'
import { spacing, device } from '../../../utils/mixins'

export const StyledTitle = styled.h1`
  font: 400 18px/25px Tenor, sans-serif;
  flex: 0 0 100%;
  margin: 0;
  margin-bottom: ${spacing(2)};

  @media ${device.tabletLand}, ${device.desktop} {
    display: block;
    flex: 0 0 100%;
    font: 400 36px/40px Tenor, sans-serif;
  }
`
