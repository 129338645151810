import styled from 'styled-components'
import { spacing, device } from '../../../utils/mixins'

export const StyledCard = styled.section`
  display: flex;
  margin-top: ${spacing(3)};
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
    margin-top: ${spacing(5)};
    flex: 0 0 50%;
    max-width: 50%;

    ${props =>
      props.fullwidth && {
        flex: '0 0 100%',
        maxWidth: '100%',
      }}
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${spacing(4, 2)};
    color: inherit;
    text-decoration: none;
    position: relative;
    min-height: 280px;
    width: 100%;
    max-width: 100%;

    @media ${device.tabletLand}, ${device.desktop} {
      padding: ${spacing(8)};
      min-height: 380px;
      justify-content: space-between;

      ${props =>
        props.fullwidth && {
          justifyContent: 'center',
        }}
    }

    &:hover {
      .title {
        color: #9a6244;
      }
    }

    .overlay {
      background-color: rgba(240, 240, 240, 0.8);
      filter: grayscale(1);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
      transition: all 300ms;
    }

    .image {
      filter: grayscale(1);
      position: absolute !important;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    .title {
      font: 400 18px/32px Tenor, sans-serif;
      margin: 0;
      position: relative;
      z-index: 3;
      transition: color 300ms;

      @media ${device.tabletLand}, ${device.desktop} {
        font-size: 36px;
        line-height: 44px;
        max-width: 660px;
      }
    }

    .author {
      position: relative;
      margin-top: ${spacing(2)};
      z-index: 3;
    }
  }
`
