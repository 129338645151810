import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/container'
import { ArticleCard } from '../components/blog'
import {
  StyledBlogCardsContainer,
  StyledLoadMoreButton,
} from '../components/blog/styledComponents'

export const query = graphql`
  query ArticlesPageQuery {
    strapiGraphql {
      articlesPage {
        pageSeo {
          title
          description
          keywords
        }
        articlesPerPage
      }

      articles {
        id
        pageTitle
        slug
        previewImage {
          url
          mediaFile {
            childImageSharp {
              fluid(quality: 100, fit: COVER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        author {
          name
          position
          avatar {
            url
            mediaFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default function Blog({ data }) {
  const { pageSeo, articlesPerPage } = data.strapiGraphql.articlesPage
  const { articles: allArticles } = data.strapiGraphql

  const [articles, setArticles] = useState(
    allArticles.slice(0, articlesPerPage),
  )
  const showLoadMoreButton = articles.length !== allArticles.length

  const handleLoadMore = () => {
    setArticles(allArticles.slice(0, articles.length + articlesPerPage))
  }

  return (
    <Layout>
      <SEO {...pageSeo} />
      <Container>
        <StyledBlogCardsContainer>
          {articles.map(
            ({ id, slug, pageTitle, previewImage, author }, index) => (
              <ArticleCard
                key={id}
                fullwidth={index % 3 === 0}
                link={`/blog/${slug}`}
                title={pageTitle}
                image={previewImage}
                author={author}
              />
            ),
          )}
        </StyledBlogCardsContainer>

        {showLoadMoreButton && (
          <StyledLoadMoreButton type='button' onClick={handleLoadMore} outline>
            Показать еще
          </StyledLoadMoreButton>
        )}
      </Container>
    </Layout>
  )
}
