import styled from 'styled-components'
import { spacing, device } from '../../../utils/mixins'

export const StyledFigure = styled.figure`
  margin-left: 0;
  margin-right: 0;

  figcaption {
    font-size: 14px;
    line-height: 20px;
    margin-top: ${spacing(1)};
    opacity: 0.8;
  }
`
