import styled from 'styled-components'
import { spacing, device } from '../../utils/mixins'

export const StyledAside = styled.aside`
  display: none;

  @media ${device.tabletLand}, ${device.desktop} {
    display: block;
    position: sticky;
    top: ${spacing(2)};
    flex: 0 0 calc(30% - ${spacing(2)});
    max-width: calc(30% - ${spacing(2)});
  }
`
