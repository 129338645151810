import styled from 'styled-components'
import { spacing, device } from '../../../utils/mixins'

export const StyledContainer = styled.div`
  border-top: 2px solid #e0e0e0;
  padding-top: ${spacing(2)};
  margin-top: ${spacing(3)};

  p {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

  button {
    height: 40px;
    max-width: 190px;
    @media ${device.desktop} {
      margin-top: ${spacing(2)};
    }
  }
`
