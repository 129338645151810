import React from 'react'
import { StyledAside } from './styledComponents'
import CallbackBlock from './callback-block'

const Aside = ({ children, ...rest }) => {
  return (
    <StyledAside {...rest}>
      {children}
      <CallbackBlock />
    </StyledAside>
  )
}

export default Aside
