import React from 'react'
import Markdown from 'react-markdown'
import { processMarkdown } from '../../../utils/markdown'
import { StyledContainer } from './styledComponents'

const MarkdownText = ({ text, ...rest }) => {
  const validatedText = processMarkdown(text)
  return (
    <StyledContainer>
      <Markdown {...rest}>{validatedText}</Markdown>
    </StyledContainer>
  )
}

export default MarkdownText
