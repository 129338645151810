import styled from 'styled-components'
import { device } from '../../../utils/mixins'

export const StyledContainer = styled.article`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  max-width: 100%;
  width: 100%;

  @media ${device.tabletLand}, ${device.desktop} {
    font-size: 18px;
    line-height: 30px;
  }
`
