import React from 'react'
import PropTypes from 'prop-types'
import { useModal } from '../../../providers/modal'
import Button from '../../button'
import { StyledContainer } from './styledComponents'

const CallbackBlock = ({ topBorder, buttonText, ...rest }) => {
  const { showCallback } = useModal()
  return (
    <StyledContainer {...rest}>
      <p>
        Отправьте заявку на бесплатную консультация по вопросам загородного
        жилищного строительства
      </p>
      <Button type='button' onClick={showCallback}>
        {buttonText}
      </Button>
    </StyledContainer>
  )
}

CallbackBlock.propTypes = {
  topBorder: PropTypes.bool,
  buttonText: PropTypes.string,
}

CallbackBlock.defaultProps = {
  topBorder: true,
  buttonText: 'Отправить заявку',
}

export default CallbackBlock
