import React from 'react'
import PropTypes from 'prop-types'
import { StyledFigure } from './styledComponents'

const VideoFigure = ({ src, ...rest }) => {
  return (
    <StyledFigure {...rest}>
      <video height='400' controls preload='metadata'>
        <source src={src} type='video/mp4'></source>
      </video>
    </StyledFigure>
  )
}

VideoFigure.propTypes = {
  src: PropTypes.string.isRequired,
}

export default VideoFigure
