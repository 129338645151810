const DOMAIN_WITH_UPLOADS_REGEX = /http(s*):\/\/\w{1,}(:|.)([1-9]{4}|\.\w{1,5})\/uploads\//gi
const UPLOADS_REGEX = /\((\/{0,})uploads\//gi

const validateUploadsUrl = text =>
  text
    .replace(
      DOMAIN_WITH_UPLOADS_REGEX,
      `${process.env.GATSBY_API_URL}/uploads/`,
    )
    .replace(UPLOADS_REGEX, `(${process.env.GATSBY_API_URL}/uploads/`)

export const processMarkdown = text => {
  if (!text) return text

  return validateUploadsUrl(text)
}
