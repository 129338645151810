import styled from 'styled-components'
import { spacing, device } from '../../utils/mixins'
import Button from '../button'
import Container from '../container'
import Aside from '../../components/aside'

export const StyledBlogCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: ${spacing(-2)};
  margin-right: ${spacing(-2)};
  margin-bottom: ${spacing(2)};
`

export const StyledLoadMoreButton = styled(Button)`
  margin-top: ${spacing(4)};
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;
`

export const StyledContainer = styled(Container)`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`

export const StyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  max-width: 100%;

  @media ${device.desktop} {
    flex: 0 0 calc(100% - 360px);
    max-width: calc(100% - 360px);
  }

  a {
    font-weight: 700;
    color: #0074b8;
    text-decoration: none;
    position: relative;
    padding: ${spacing(0, 0, 1 / 2)};

    &::before {
      background-color: rgba(0, 116, 184, 0.3);
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      width: 100%;
      transform: scaleX(1);
      transition: all 300ms;
    }

    &:hover {
      &::before {
        transform: scaleX(0);
      }
    }
  }
`

export const StyledAside = styled(Aside)`
  @media ${device.tabletLand} {
    display: none;
  }
`
