import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { StyledFigure } from './styledComponents'

const ImageFigure = ({ fluid, alt, caption, ...rest }) => {
  return (
    <StyledFigure {...rest}>
      <Img fluid={fluid} alt={alt || 'image'} />
      {caption && <figcaption>{caption}</figcaption>}
    </StyledFigure>
  )
}

ImageFigure.propTypes = {
  fluid: PropTypes.object,
  alt: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
}

export default ImageFigure
